import { SearchIcon } from "lucide-react";
import { Input } from "./ui/input";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { cn } from "@/lib/utils";

interface Props {
  placeholder?: string;
  className?: string;
  onChange?: (value: string) => void;
}

const CommonNavbarSearch = ({
  placeholder = "Search products...",
  className,
  onChange,
}: Props) => {
  const [search, setSearch] = useState("");

  const [debouncedSearch] = useDebounce(search, 1000);

  useEffect(() => {
    // update the url search params
    const url = new URL(window.location.href);
    url.searchParams.set("search", debouncedSearch);
    window.history.replaceState({}, "", url.href);

    if (onChange) {
      onChange(debouncedSearch);
    }
  }, [debouncedSearch]);

  return (
    <>
      <div className="relative">
        <Input
          type="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={placeholder}
          className={cn("w-full pl-10", className)}
        />
        <SearchIcon className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-kp-secondary" />
      </div>
    </>
  );
};

export default CommonNavbarSearch;
