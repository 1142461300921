import Login from "@/components/auth/login";
import Register from "@/components/auth/register";
import CommonNavbarSearch from "@/components/common-navbar-search";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { $productSearchTerm } from "@/store/product-filtering";
import type { Shop, User } from "@/types";
import { Menu, Search, X } from "lucide-react";
import { useState, type PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  shop: Shop;
  user?: User | null;
}

const Header = ({ shop, user, children }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [shouldLogin, setShouldLogin] = useState(false);
  const [shouldRegister, setShouldRegister] = useState(false);

  const handleSearch = (term: string) => {
    if (!term) {
      return;
    }
    // check if we're on the products page first
    if (window.location.pathname !== `/shops/${shop.slug}/products`) {
      window.location.href = `/shops/${shop.slug}/products?search=${term}`;
    } else {
      // update store
      $productSearchTerm.set(term);
    }
  };

  return (
    <header className="bg-kp-white sticky top-0 z-50">
      <div className="container mx-auto px-2">
        {/* Mobile Header */}
        <div className="lg:hidden flex items-center justify-between py-3">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="p-2 hover:bg-kp-accent/10 rounded-full flex-shrink-0"
            aria-label="Toggle menu">
            {isMenuOpen ? (
              <X className="w-6 h-6 text-kp-black" />
            ) : (
              <Menu className="w-6 h-6 text-kp-black" />
            )}
          </button>

          <a
            href={`/shops/${shop?.slug}`}
            className="flex items-center flex-grow justify-center">
            <img
              src={shop.logo || ""}
              alt={shop.name}
              className="h-10 w-10 mr-2 flex-shrink-0"
            />
            <div className="text-center">
              <div className="text-kp-primary text-sm font-bold leading-tight">
                {shop?.name}
              </div>
              <div className="text-xs font-normal text-kp-secondary leading-tight">
                {shop?.slogan}
              </div>
            </div>
          </a>

          <div className="flex items-center gap-2 flex-shrink-0">
            <button
              onClick={() => setIsSearchOpen(!isSearchOpen)}
              className="p-2 hover:bg-kp-accent/10 rounded-full"
              aria-label="Toggle search">
              <Search className="w-6 h-6 text-kp-black" />
            </button>

            {/* cart + favourite button */}
            {children}
          </div>
        </div>

        {/* Mobile Search */}
        {isSearchOpen && (
          <div className="lg:hidden py-3 px-2 border-t border-kp-black/10">
            <CommonNavbarSearch
              placeholder={`Search products in ${shop.name}`}
              className="w-full pl-10 border border-kp-black/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-kp-primary focus:border-0 focus-visible:ring-kp-primary transition-all"
              onChange={handleSearch}
            />
          </div>
        )}

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="lg:hidden border-t border-kp-black/10">
            <nav className="py-4 space-y-4">
              <div className="flex flex-col gap-2">
                {user ? (
                  <>
                    <a href="/dashboard">
                      <button className="w-full px-4 py-2 text-sm font-medium border rounded transition-colors text-center hover:bg-kp-black hover:text-kp-accent">
                        My Account
                      </button>
                    </a>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => setShouldLogin(true)}
                      className="w-full px-4 py-2 text-sm font-medium border rounded transition-colors text-center hover:bg-kp-black hover:text-kp-accent">
                      Login
                    </button>

                    <button
                      onClick={() => setShouldRegister(true)}
                      className="w-full px-4 py-2 bg-kp-black hover:text-kp-accent text-kp-white rounded text-sm font-medium transition-colors">
                      Sign Up
                    </button>
                  </>
                )}
              </div>
            </nav>
          </div>
        )}

        {/* Desktop Header */}
        <div className="hidden lg:flex items-center justify-between py-4 gap-8">
          <a
            href={`/shops/${shop?.slug}`}
            className="text-2xl font-bold shrink-0 flex items-center">
            <img
              src={shop.logo || ""}
              alt={shop.name}
              className="h-16 w-16 mr-2"
            />
            <div>
              <div className="text-kp-primary">{shop?.name}</div>
              <div className="text-sm font-normal text-kp-secondary">
                {shop?.slogan}
              </div>
            </div>
          </a>

          <div className="flex-1 max-w-3xl">
            <CommonNavbarSearch
              placeholder={`Search products in ${shop.name}`}
              className="w-full pl-10 border border-kp-black/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-kp-primary focus:border-0 focus-visible:ring-kp-primary transition-all"
              onChange={handleSearch}
            />
          </div>

          <div className="flex items-center gap-4">
            {/* cart + favourite button */}
            {children}

            {user ? (
              <>
                <a href="/dashboard">
                  <button className="px-4 py-2 text-sm font-medium hover:bg-kp-accent/10 rounded transition-colors">
                    My Account
                  </button>
                </a>
              </>
            ) : (
              <>
                <button
                  onClick={() => setShouldLogin(true)}
                  className="px-4 py-2 text-sm font-medium hover:bg-kp-accent/10 rounded transition-colors">
                  Login
                </button>
                <button
                  onClick={() => setShouldRegister(true)}
                  className="px-4 py-2 bg-kp-black text-kp-white rounded text-sm font-medium hover:bg-kp-accent hover:text-kp-black transition-colors">
                  Sign Up
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* login modal */}
      <Dialog open={shouldLogin} onOpenChange={setShouldLogin}>
        <DialogContent className="sm:max-w-[425px]">
          <Login
            siteName={shop.name}
            buttonClasses="bg-kp-black hover:bg-kp-black hover:opacity-90">
            <div className="mx-auto flex gap-1 text-sm">
              <p>Don&apos;t have an account yet?</p>
              <button
                type="button"
                className="underline"
                onClick={() => {
                  setShouldLogin(false);
                  setShouldRegister(true);
                }}>
                Sign Up
              </button>
            </div>
          </Login>
        </DialogContent>
      </Dialog>

      {/* register modal */}
      <Dialog open={shouldRegister} onOpenChange={setShouldRegister}>
        <DialogContent className="sm:max-w-[425px]">
          <Register
            siteName={shop.name}
            enableShopRegistration={false}
            buttonClasses="bg-kp-black hover:bg-kp-black hover:opacity-90">
            <div className="mx-auto flex gap-1 text-sm">
              <p>Already have an account?</p>
              <button
                type="button"
                className="underline"
                onClick={() => {
                  setShouldRegister(false);
                  setShouldLogin(true);
                }}>
                Login
              </button>
            </div>
          </Register>
        </DialogContent>
      </Dialog>
    </header>
  );
};

export default Header;
